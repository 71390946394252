.list {
    max-height: 600px;
    overflow: auto;
    padding-inline: 5px;
}
.record {
    display: flex;
    border-style: solid;
    border-color: #e9e8ed;
}
.text {
    color: #d8d8d8;
}
