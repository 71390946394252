// Utilities
@import "../utilities/variables.scss";

.modal-header,
.modal-footer {
    border: none;
}

.modal-header {
    padding-bottom: 0.2em;
}

.modal-content {
    background-color: $primary;
}
