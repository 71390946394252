.switch {
    & > input {
        transform: scale(1.8);

        &.unchecked {
            background-image: url("../../assets/toggle-search-icon.png") !important;
        }

        &.checked {
            background-image: url("../../assets/toggle-polygon-icon.png") !important;
        }
    }
}
