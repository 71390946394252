.sidebar {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;

        li {
            button,
            a {
                font-size: 35px;
                color: white !important;
                background: none !important;
                text-decoration: none;

                @media (min-width: 768px) {
                    font-size: 20px;
                }

                svg {
                    margin-right: 8px;
                    margin-bottom: 6px;
                }
            }
        }
    }
}
