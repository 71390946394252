.layout {
    display: flex;
    gap: 20px;

    .sidebar {
        max-width: 300px;
        width: 20%;
        height: 100vh;
    }

    .outlet {
        width: 80%;
        margin-left: 25%;

        @media screen and (min-width: 768px) {
            margin-left: 25%;
        }

        @media screen and (min-width: 1575px) {
            margin-left: 340px;
        }
    }
}
