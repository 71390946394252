// Utilities
@import "../utilities/variables.scss";

html,
body {
    margin: 0;
    padding: 0;
}

html,
body,
#root,
main {
    height: 100%;
}

body {
    background-color: $primary;
    color: rgba(244, 244, 245, 0.9);
}

.bg-base-primary {
    background-color: $primary;
}

.bg24 {
    background: rgba(0, 0, 0, 0.24);
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
